import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-select-analysis',
  templateUrl: './select-analysis.component.html',
  styleUrls: ['./select-analysis.component.scss'],
})
export class SelectAnalysisComponent implements OnInit {

  constructor(private popOverController: PopoverController, public auth: AuthService) { }

  ngOnInit() {}

  dismiss(route) {
    this.popOverController.dismiss(route);
  }
}
