import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { MapDataService } from '../../../services/map-data/map-data.service';

@Component({
  selector: 'app-search-area',
  templateUrl: './search-area.component.html',
  styleUrls: ['./search-area.component.scss'],
})
export class SearchAreaComponent implements OnInit {

  redes = [];
  filteredRedes = [];
  showSearchResults = false;
  handlingInput;
  constructor(private popoverController: PopoverController, private mapData: MapDataService) {
    this.getMapData();
  }

  ngOnInit() {}

  async getMapData() {
    await this.mapData.getMunicipios();
    this.redes = this.mapData.statesData.map(el => {
      return {
        nome: el.nome,
        ibgeId: el.id,
        grupo: 'Estados'
      };
    });
    this.redes.push(...this.mapData.citiesData.map(el => {
      return {
        nome: el.nome,
        ibgeId: el.id,
        grupo: 'Cidades'
      };
    }));
  }
  handleInput(event) {
    if (this.redes === []) {
      if (this.handlingInput) {
        clearTimeout(this.handlingInput);
      }
      this.handlingInput = setTimeout(() => { this.handleInput(event); }, 100);
    }
    if (this.handlingInput) {
      clearTimeout(this.handlingInput);
    }
    const query = event.target.value.toLowerCase();
    requestAnimationFrame(() => {
      if (query.length >= 2) {
        this.showSearchResults = true;
        this.filteredRedes = this.redes.filter(el => {
          return (el.nome.toLowerCase().indexOf(query) === 0 && query.length >= 2);
        });
      } else {
        this.showSearchResults = false;
      }
    });
  }

  resolve(rede) {
    this.popoverController.dismiss(rede);
  }
}
