import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-indicadores-options',
  templateUrl: './indicadores-options.component.html',
  styleUrls: ['./indicadores-options.component.scss'],
})
export class IndicadoresOptionsComponent implements OnInit {

  selected = [];
  @Input() options = [];
  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() {}

  valueChanged(ev, indicador, grupo) {
    if (ev.detail.checked) {
      indicador.grupo = grupo.nome;
      this.selected.push(indicador);
    } else {
      const id = this.selected.findIndex(el => el.nome === indicador.nome);
      this.selected.splice(id, 1);
    }
  }

  save(metric) {
    this.popoverCtrl.dismiss(this.selected);
  }
}
