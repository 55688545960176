import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { TableComponent } from './table.component';
import { TableFooterComponent } from './table-footer/table-footer.component';

@NgModule({
  declarations: [
    TableComponent, TableFooterComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    IonicModule,
    NgxDatatableModule
  ],
  exports: [ TableComponent, TableFooterComponent ],
  providers: [],
  bootstrap: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class TableModule {}
