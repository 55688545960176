import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MapDataService {

  constructor(private http: HttpClient) {
    this.getEstados();
    this.getMunicipios();
   }

  statesData = [];
  citiesData = [];
  promiseMunicipios;
  async getMunicipios() {
    if (this.promiseMunicipios) {
      return await this.promiseMunicipios;
    } else {
      this.promiseMunicipios = this.http.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios`).toPromise();
      this.promiseMunicipios.then(result => {
        this.citiesData = result as any;
      });
      return await this.promiseMunicipios;
    }
  }
  getEstados() {
    this.http.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`).subscribe(result => {
      this.statesData = result as any;
    });
  }

  getMapData() {
    return this.http.get(`https://servicodados.ibge.gov.br/api/v2/malhas/BR?formato=application/vnd.geo+json&resolucao=2`,
    { headers: { Accept : 'application/json'}});
  }

  getStateData(state) {
    return this.http.get(`https://servicodados.ibge.gov.br/api/v2/malhas/${state}?formato=application/vnd.geo+json&resolucao=5`,
          { headers: { Accept : 'application/json'}});
  }


  getCityStateName(ibgeId) {
    return this.getAreaName(ibgeId.slice(0, 2));
  }

  getAreaName(ibgeId) {
    if (ibgeId === 'BR') {
      return 'Brasil';
    }
    if (ibgeId.length > 2) {
      return this.citiesData.find(d => d.id === +ibgeId).nome;
    } else {
      return this.statesData.find(d => d.id === +ibgeId).nome;
    }
  }
}
