import { Injectable, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { auth } from 'firebase/app';
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { ACCESS } from '../constants/admins';

interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  access?: number;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: BehaviorSubject<User> = new BehaviorSubject(null);
  userAccess = 0;
  hasLoggedIn = false;
  private idToken: string;

  constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore, public router: Router) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        userRef.get().toPromise().then(dataRef => {
          const userData = dataRef.data();
          this.userAccess = userData.access;
          if (user.email.match(/.*@fundacaolemann.org.br$/) ||
              user.email.match(/.*@gmfy.it$/) ||
              userData.access >= 0) {
            this.userData.next(user);
            localStorage.setItem('user', JSON.stringify(this.userData.value));
            this.setIdToken();
            JSON.parse(localStorage.getItem('user'));
            if (this.hasLoggedIn) {
              this.router.navigate(['home-lemann']);
            }
          } else {
            localStorage.setItem('user', null);
          }
        });
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  login(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then((result) => {
          // const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);
          // userRef.get().toPromise().then(dataRef => {
          //   const userData = dataRef.data();
          //   this.setUserData(result.user, userData);
          //   this.router.navigate(['home-lemann']);
          //   return resolve(result);
          // });
        }).catch((error) => {
          return reject(error);
        });
    });
  }

  loginWithGoogle() {
    return this.googleAuthLogin(new auth.GoogleAuthProvider());
  }

  googleAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);
      userRef.get().toPromise().then(dataRef => {
        const userData = dataRef.data();
        console.log(userData);
        if (userData) {
          this.setUserData(result.user, userData);
          this.userAccess = userData.access || 0;
          this.hasLoggedIn = true;
        } else if (result.user.email.match(/.*@fundacaolemann.org.br$/) ||
        result.user.email.match(/.*@gmfy.it$/)) {
          this.setUserData(result.user, {access : 0});
          this.userAccess = 0;
          this.hasLoggedIn = true;
        } else {
          setTimeout(() => {
            this.logout();
            setTimeout(() => {
              window.alert('Desculpe! Apenas domínios autorizados podem realizar login usando uma conta Google!');
            }, 50);
          }, 0);
        }
      });
    }).catch((error) => {
      window.alert(error);
    });
  }

  register(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password)
        .then((result) => {
          this.setUserData(result.user, {});
          this.router.navigate(['home-lemann']);
          return resolve(result);
        }).catch((error) => {
          return reject(error);
        });
    });
  }

  logout() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.userData.next(null);
      this.router.navigate(['login']);
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  setUserData(user, data) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      access: data.access || 0
    };
    return userRef.set(userData, {
      merge: true
    });
  }

  setIdToken() {
    this.afAuth.auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
          localStorage.setItem('idToken', idToken);
          this.idToken = idToken;
        });
      }
    });
  }

  checkIdToken() {
    if (this.idToken) {
      return true;
    } else if (localStorage.getItem('idToken')) {
      this.idToken = localStorage.getItem('idToken');
    } else {
      return false;
    }
  }

  getIdToken() {
    return this.idToken;
  }
}
