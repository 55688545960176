import { ToastController } from '@ionic/angular';

export class ToastService {

  constructor(public toastController: ToastController) {}

  async openToast(parameters) {
    const toast = await this.toastController.create({
      message: parameters.message,
      color: parameters.color,
      duration: 4000,
      position: 'top'
    });
    toast.present();
  }

  async presentToastWithOptions() {
    const toast = await this.toastController.create({
      header: 'Toast header',
      message: 'Click to Close',
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            
          }
        }
      ]
    });
    toast.present();
  }

}