import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-account-options',
  templateUrl: './account-options.component.html',
  styleUrls: ['./account-options.component.scss'],
})
export class AccountOptionsComponent {

  constructor(public authService: AuthService, private popoverCtrl: PopoverController) { }


  logout() {
    this.authService.logout();
    this.popoverCtrl.dismiss();
  }

}
