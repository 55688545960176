import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-select-projects',
  templateUrl: './select-projects.component.html',
  styleUrls: ['./select-projects.component.scss'],
})
export class SelectProjectsComponent implements OnInit {

  constructor(private popOverController: PopoverController) { }

  ngOnInit() {}

  dismiss(route) {
    this.popOverController.dismiss(route);
  }
}
