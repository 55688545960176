import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DragulaModule } from 'ng2-dragula';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PopOversModule } from './components/pop-overs/pop-overs.module';
import { AuthModule } from './pages/auth/auth.module';
import { AuthService } from './services/auth.service';
import { FormsModule } from '@angular/forms';
import { ToastService } from './services/toastr.service';
import { TableModule } from './components/table/table.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    PopOversModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    DragulaModule.forRoot(),
    TableModule,
    AuthModule
  ],
  providers: [
    AuthService,
    ToastService,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
