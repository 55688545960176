export const INDICADORES = [
    {
        nome: 'Censo',
        subs: [
            {
                nome: 'Censo',
                subs: [
                    {
                        nome: 'Matriculas',
                        subs: [
                            {
                                nome: 'Matrículas - Totais',
                                prop: ['matriculas', 'total'],
                                type: 'year',
                                decimal: 0
                            },
                            {
                                nome: 'Matrículas - 1º a 5º Ano',
                                prop: ['matriculas', 'iniciais'],
                                type: 'year',
                                decimal: 0
                            },
                            {
                                nome: 'Matrículas - 6º a 9º Ano',
                                prop: ['matriculas', 'finais'],
                                type: 'year',
                                decimal: 0
                            }
                        ]
                    },
                    {
                        nome: 'Escolas',
                        subs: [
                            {
                                nome: 'Escolas - Total',
                                prop: ['numEscolas'],
                                type: 'year',
                                decimal: 0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        nome: 'Aprendizagem',
        subs: [
            {
                nome: 'ANA',
                subs: [
                    {
                        nome: 'ANA - Matemática',
                        prop: ['anaData', 'mat'],
                        levels: [
                            {id: 0, title: 'Nivel 1 (elementar)', prop: 'nivel1'},
                            {id: 1, title: 'Nivel 2 (básico)', prop: 'nivel2'},
                            {id: 2, title: 'Nivel 3 (adequado)', prop: 'nivel3'},
                            {id: 3, title: 'Nivel 4 (desejado)', prop: 'nivel4'},
                        ],
                        type: 'stacked'
                    },
                    // {
                    //     nome: 'ANA - Lingua Portuguesa (escrita)',
                    //     prop: ['anaData', 'escrita'],
                    //     levels: [
                    //         {id: 0, title: 'Nivel 1', prop: 'nivel1'},
                    //         {id: 1, title: 'Nivel 2', prop: 'nivel2'},
                    //         {id: 2, title: 'Nivel 3', prop: 'nivel3'},
                    //         {id: 3, title: 'Nivel 4', prop: 'nivel4'},
                    //         {id: 4, title: 'Nivel 5', prop: 'nivel5'},
                    //     ],
                    //     type: 'stacked'
                    // },
                    {
                        nome: 'ANA - Lingua Portuguesa (leitura)',
                        prop: ['anaData', 'leitura'],
                        levels: [
                            {id: 0, title: 'Nivel 1 (elementar)', prop: 'nivel1'},
                            {id: 1, title: 'Nivel 2 (básico)', prop: 'nivel2'},
                            {id: 2, title: 'Nivel 3 (adequado)', prop: 'nivel3'},
                            {id: 3, title: 'Nivel 4 (desejado)', prop: 'nivel4'},
                        ],
                        type: 'stacked'
                    }
                ]
            },
            {
                nome: 'IDEB',
                subs: [
                    {
                        nome: 'IDEB',
                        subs: [
                            {
                                nome: 'IDEB - Anos Iniciais',
                                prop: ['idebData', 'iniciais', 'score'],
                                type: 'year'
                            },
                            {
                                nome: 'IDEB - Anos Finais',
                                prop: ['idebData', 'finais', 'score'],
                                type: 'year'
                            }
                        ]
                    },
                    {
                        nome: 'IDEB nota matemática',
                        subs: [
                            {
                                nome: 'IDEB nota matemática - Anos Iniciais',
                                prop: ['idebData', 'iniciais', 'scoreMatematicaMedio'],
                                type: 'year'
                            },
                            {
                                nome: 'IDEB nota matemática - Anos Finais',
                                prop: ['idebData', 'finais', 'scoreMatematicaMedio'],
                                type: 'year'
                            }
                        ]
                    },
                    {
                        nome: 'IDEB nota matemática - aprendizagem adequada (%)',
                        subs: [
                            {
                                nome: 'IDEB nota matemática - aprendizagem adequada (%) - Anos Iniciais',
                                prop: [['idebData', 'iniciais', 'proficienciaMat', 'avancado'],
                                       ['idebData', 'iniciais', 'proficienciaMat', 'proficiente']],
                                op: 'sum',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'IDEB nota matemática - aprendizagem adequada (%) - Anos Finais',
                                prop: [['idebData', 'finais', 'proficienciaMat', 'avancado'],
                                       ['idebData', 'finais', 'proficienciaMat', 'proficiente']],
                                op: 'sum',
                                type: 'yearPercent'
                            }
                        ]
                    },
                    {
                        nome: 'IDEB nota português',
                        subs: [
                            {
                                nome: 'IDEB nota português - Anos Iniciais',
                                prop: ['idebData', 'iniciais', 'scorePortuguesMedio'],
                                type: 'year'
                            },
                            {
                                nome: 'IDEB nota português - Anos Finais',
                                prop: ['idebData', 'finais', 'scorePortuguesMedio'],
                                type: 'year'
                            }
                        ]
                    },
                    {
                        nome: 'IDEB nota português - aprendizagem adequada (%)',
                        subs: [
                            {
                                nome: 'IDEB nota português - aprendizagem adequada (%) - Anos Iniciais',
                                prop: [['idebData', 'iniciais', 'proficienciaPt', 'avancado'],
                                       ['idebData', 'iniciais', 'proficienciaPt', 'proficiente']],
                                op: 'sum',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'IDEB nota português - aprendizagem adequada (%) - Anos Finais',
                                prop: [['idebData', 'finais', 'proficienciaPt', 'avancado'],
                                       ['idebData', 'finais', 'proficienciaPt', 'proficiente']],
                                op: 'sum',
                                type: 'yearPercent'
                            },
                        ]
                    },
                    {
                        nome: 'IDEB - Rendimento',
                        subs: [
                            {
                                nome: 'IDEB - Rendimento - Anos Iniciais',
                                prop: ['idebData', 'iniciais', 'rendimento'],
                                type: 'yearPercent'
                            },
                            {
                                nome: 'IDEB - Rendimento - Anos Finais',
                                prop: ['idebData', 'finais', 'rendimento'],
                                type: 'yearPercent'
                            }
                        ]
                    },
                    {
                        nome: 'IDEB - Nota',
                        subs: [
                            {
                                nome: 'IDEB - Nota - Anos Iniciais',
                                prop: ['idebData', 'iniciais', 'resultadosAprendizado'],
                                type: 'year'
                            },
                            {
                                nome: 'IDEB - Nota - Anos Finais',
                                prop: ['idebData', 'finais', 'resultadosAprendizado'],
                                type: 'year'
                            }
                        ]
                    },
                ]
            },
            {
                nome: 'Avaliação Externa - CAEd',
                subs: [
                    {
                        nome: 'CAEd - Proficiência Matemática',
                        subs: [
                            {
                                nome: 'CAEd - Proficiência Matemática - 2º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: ['caedData', '2', 'matematica', 'proficienciaMedia'],
                                type: 'year'
                            },
                            {
                                nome: 'CAEd - Proficiência Matemática - 5º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: ['caedData', '5', 'matematica', 'proficienciaMedia'],
                                type: 'year'
                            },
                            {
                                nome: 'CAEd - Proficiência Matemática - 9º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: ['caedData', '9', 'matematica', 'proficienciaMedia'],
                                type: 'year'
                            }
                        ]
                    },
                    {
                        nome: 'CAEd - Proficiência Português',
                        subs: [
                            {
                                nome: 'CAEd - Proficiência Português - 2º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: ['caedData', '2', 'lingua portuguesa', 'proficienciaMedia'],
                                type: 'year'
                            },
                            {
                                nome: 'CAEd - Proficiência Português - 5º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: ['caedData', '5', 'lingua portuguesa', 'proficienciaMedia'],
                                type: 'year'
                            },
                            {
                                nome: 'CAEd - Proficiência Português - 9º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: ['caedData', '9', 'lingua portuguesa', 'proficienciaMedia'],
                                type: 'year'
                            }
                        ]
                    },
                    {
                        nome: 'CAEd - % Aprendizagem Adequada - Matemática',
                        subs: [
                            {
                                nome: 'CAEd - % Aprendizagem Adequada - Matemática - 2º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '2', 'matematica', 'nivel3'],
                                       ['caedData', '2', 'matematica', 'nivel4']],
                                op: 'sum',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'CAEd - % Aprendizagem Adequada - Matemática - 5º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '5', 'matematica', 'nivel3'],
                                       ['caedData', '5', 'matematica', 'nivel4']],
                                op: 'sum',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'CAEd - % Aprendizagem Adequada - Matemática - 9º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '9', 'matematica', 'nivel3'],
                                       ['caedData', '9', 'matematica', 'nivel4']],
                                op: 'sum',
                                type: 'yearPercent'
                            }
                        ]
                    },
                    {
                        nome: 'CAEd - % Aprendizagem Adequada - Português',
                        subs: [
                            {
                                nome: 'CAEd - % Aprendizagem Adequada - Português - 2º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '2', 'lingua portuguesa', 'nivel3'],
                                       ['caedData', '2', 'lingua portuguesa', 'nivel4']],
                                op: 'sum',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'CAEd - % Aprendizagem Adequada - Português - 5º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '5', 'lingua portuguesa', 'nivel3'],
                                       ['caedData', '5', 'lingua portuguesa', 'nivel4']],
                                op: 'sum',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'CAEd - % Aprendizagem Adequada - Português - 9º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '9', 'lingua portuguesa', 'nivel3'],
                                       ['caedData', '9', 'lingua portuguesa', 'nivel4']],
                                op: 'sum',
                                type: 'yearPercent'
                            }
                        ]
                    },
                    {
                        nome: 'CAEd - Taxa de Participação - Matemática',
                        subs: [
                            {
                                nome: 'CAEd - Taxa de Participação - Matemática - 2º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '2', 'matematica', 'estudantesEfetivos'],
                                       ['caedData', '2', 'matematica', 'estudantesPrevistos']],
                                op: 'divide',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'CAEd - Taxa de Participação - Matemática - 5º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '5', 'matematica', 'estudantesEfetivos'],
                                       ['caedData', '5', 'matematica', 'estudantesPrevistos']],
                                op: 'divide',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'CAEd - Taxa de Participação - Matemática - 9º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '9', 'matematica', 'estudantesEfetivos'],
                                       ['caedData', '9', 'matematica', 'estudantesPrevistos']],
                                op: 'divide',
                                type: 'yearPercent'
                            }
                        ]
                    },
                    {
                        nome: 'CAEd - Taxa de Participação - Português',
                        subs: [
                            {
                                nome: 'CAEd - Taxa de Participação - Português - 2º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '2', 'lingua portuguesa', 'estudantesEfetivos'],
                                       ['caedData', '2', 'lingua portuguesa', 'estudantesPrevistos']],
                                op: 'divide',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'CAEd - Taxa de Participação - Português - 5º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '2', 'lingua portuguesa', 'estudantesEfetivos'],
                                       ['caedData', '2', 'lingua portuguesa', 'estudantesPrevistos']],
                                op: 'divide',
                                type: 'yearPercent'
                            },
                            {
                                nome: 'CAEd - Taxa de Participação - Português - 9º Ano',
                                warn: 'Avaliações estaduais podem possuir matrizes diferentes e não ser comparáveis.',
                                prop: [['caedData', '2', 'lingua portuguesa', 'estudantesEfetivos'],
                                       ['caedData', '2', 'lingua portuguesa', 'estudantesPrevistos']],
                                op: 'divide',
                                type: 'yearPercent'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        nome: 'Processo',
        subs: [
            {
                nome: 'IDEF',
                prog: 'formar',
                subs: [
                    {
                        nome: 'IDEF - Valor',
                        prop: ['idef', '$mes', 'valor'],
                        type: 'month'
                    },
                    {
                        nome: 'IDEF - Avaliação',
                        prop: ['idef', '$mes', 'avaliacao'],
                        type: 'month'
                    },
                    {
                        nome: 'IDEF - Curriculo',
                        prop: ['idef', '$mes', 'curriculo'],
                        type: 'month'
                    },
                    {
                        nome: 'IDEF - Formação',
                        prop: ['idef', '$mes', 'formacao'],
                        type: 'month'
                    },
                    {
                        nome: 'IDEF - Observação',
                        prop: ['idef', '$mes', 'observacao'],
                        type: 'month'
                    },
                    {
                        nome: 'IDEF - Planejamento',
                        prop: ['idef', '$mes', 'planejamento'],
                        type: 'month'
                    }
                ]
            },
            {
                nome: 'SAEV',
                prog: 'epv',
                subs: [
                    {
                        nome: 'SAEV - PL201',
                        prop: ['saev', 'PL201'],
                        levels: [
                            {id: 0, title: 'Não Leitor', prop: 'naoLeitorPorcentagem'},
                            {id: 1, title: 'Leitor de Sílaba', prop: 'leitorSilabaPorcentagem'},
                            {id: 2, title: 'Leitor de Palavra', prop: 'leitorPalavraPorcentagem'},
                            {id: 3, title: 'Leitor de Frase', prop: 'leitorFrasePorcentagem'},
                            {id: 4, title: 'Leitor sem Fluência', prop: 'leitorSemFluenciaPorcentagem'},
                            {id: 5, title: 'Leitor Fluente', prop: 'leitorFluentePorcentagem'},
                            {id: 6, title: 'Não Avaliado', prop: 'naoAvaliadoPorcentagem'},
                        ],
                        type: 'stacked'
                    },
                    {
                        nome: 'SAEV - PL202',
                        prop: ['saev', 'PL202'],
                        levels: [
                            {id: 0, title: 'Não Leitor', prop: 'naoLeitorPorcentagem'},
                            {id: 1, title: 'Leitor de Sílaba', prop: 'leitorSilabaPorcentagem'},
                            {id: 2, title: 'Leitor de Palavra', prop: 'leitorPalavraPorcentagem'},
                            {id: 3, title: 'Leitor de Frase', prop: 'leitorFrasePorcentagem'},
                            {id: 4, title: 'Leitor sem Fluência', prop: 'leitorSemFluenciaPorcentagem'},
                            {id: 5, title: 'Leitor Fluente', prop: 'leitorFluentePorcentagem'},
                            {id: 6, title: 'Não Avaliado', prop: 'naoAvaliadoPorcentagem'},
                        ],
                        type: 'stacked'
                    },
                    {
                        nome: 'SAEV - PL203',
                        prop: ['saev', 'PL203'],
                        levels: [
                            {id: 0, title: 'Não Leitor', prop: 'naoLeitorPorcentagem'},
                            {id: 1, title: 'Leitor de Sílaba', prop: 'leitorSilabaPorcentagem'},
                            {id: 2, title: 'Leitor de Palavra', prop: 'leitorPalavraPorcentagem'},
                            {id: 3, title: 'Leitor de Frase', prop: 'leitorFrasePorcentagem'},
                            {id: 4, title: 'Leitor sem Fluência', prop: 'leitorSemFluenciaPorcentagem'},
                            {id: 5, title: 'Leitor Fluente', prop: 'leitorFluentePorcentagem'},
                            {id: 6, title: 'Não Avaliado', prop: 'naoAvaliadoPorcentagem'},
                        ],
                        type: 'stacked'
                    },
                    {
                        nome: 'SAEV - PL204',
                        prop: ['saev', 'PL204'],
                        levels: [
                            {id: 0, title: 'Não Leitor', prop: 'naoLeitorPorcentagem'},
                            {id: 1, title: 'Leitor de Sílaba', prop: 'leitorSilabaPorcentagem'},
                            {id: 2, title: 'Leitor de Palavra', prop: 'leitorPalavraPorcentagem'},
                            {id: 3, title: 'Leitor de Frase', prop: 'leitorFrasePorcentagem'},
                            {id: 4, title: 'Leitor sem Fluência', prop: 'leitorSemFluenciaPorcentagem'},
                            {id: 5, title: 'Leitor Fluente', prop: 'leitorFluentePorcentagem'},
                            {id: 6, title: 'Não Avaliado', prop: 'naoAvaliadoPorcentagem'},
                        ],
                        type: 'stacked'
                    },
                    {
                        nome: 'SAEV - PL205',
                        prop: ['saev', 'PL205'],
                        levels: [
                            {id: 0, title: 'Não Leitor', prop: 'naoLeitorPorcentagem'},
                            {id: 1, title: 'Leitor de Sílaba', prop: 'leitorSilabaPorcentagem'},
                            {id: 2, title: 'Leitor de Palavra', prop: 'leitorPalavraPorcentagem'},
                            {id: 3, title: 'Leitor de Frase', prop: 'leitorFrasePorcentagem'},
                            {id: 4, title: 'Leitor sem Fluência', prop: 'leitorSemFluenciaPorcentagem'},
                            {id: 5, title: 'Leitor Fluente', prop: 'leitorFluentePorcentagem'},
                            {id: 6, title: 'Não Avaliado', prop: 'naoAvaliadoPorcentagem'},
                        ],
                        type: 'stacked'
                    },
                    {
                        nome: 'SAEV - PL206',
                        prop: ['saev', 'PL206'],
                        levels: [
                            {id: 0, title: 'Não Leitor', prop: 'naoLeitorPorcentagem'},
                            {id: 1, title: 'Leitor de Sílaba', prop: 'leitorSilabaPorcentagem'},
                            {id: 2, title: 'Leitor de Palavra', prop: 'leitorPalavraPorcentagem'},
                            {id: 3, title: 'Leitor de Frase', prop: 'leitorFrasePorcentagem'},
                            {id: 4, title: 'Leitor sem Fluência', prop: 'leitorSemFluenciaPorcentagem'},
                            {id: 5, title: 'Leitor Fluente', prop: 'leitorFluentePorcentagem'},
                            {id: 6, title: 'Não Avaliado', prop: 'naoAvaliadoPorcentagem'},
                        ],
                        type: 'stacked'
                    },
                    {
                        nome: 'SAEV - PL207',
                        prop: ['saev', 'PL207'],
                        levels: [
                            {id: 0, title: 'Não Leitor', prop: 'naoLeitorPorcentagem'},
                            {id: 1, title: 'Leitor de Sílaba', prop: 'leitorSilabaPorcentagem'},
                            {id: 2, title: 'Leitor de Palavra', prop: 'leitorPalavraPorcentagem'},
                            {id: 3, title: 'Leitor de Frase', prop: 'leitorFrasePorcentagem'},
                            {id: 4, title: 'Leitor sem Fluência', prop: 'leitorSemFluenciaPorcentagem'},
                            {id: 5, title: 'Leitor Fluente', prop: 'leitorFluentePorcentagem'},
                            {id: 6, title: 'Não Avaliado', prop: 'naoAvaliadoPorcentagem'},
                        ],
                        type: 'stacked'
                    },
                    {
                        nome: 'SAEV - PL208',
                        prop: ['saev', 'PL208'],
                        levels: [
                            {id: 0, title: 'Não Leitor', prop: 'naoLeitorPorcentagem'},
                            {id: 1, title: 'Leitor de Sílaba', prop: 'leitorSilabaPorcentagem'},
                            {id: 2, title: 'Leitor de Palavra', prop: 'leitorPalavraPorcentagem'},
                            {id: 3, title: 'Leitor de Frase', prop: 'leitorFrasePorcentagem'},
                            {id: 4, title: 'Leitor sem Fluência', prop: 'leitorSemFluenciaPorcentagem'},
                            {id: 5, title: 'Leitor Fluente', prop: 'leitorFluentePorcentagem'},
                            {id: 6, title: 'Não Avaliado', prop: 'naoAvaliadoPorcentagem'},
                        ],
                        type: 'stacked'
                    },
                ]
            }
        ]
    },

];
