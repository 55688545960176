import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-search-rede',
  templateUrl: './search-rede.component.html',
  styleUrls: ['./search-rede.component.scss'],
})
export class SearchRedeComponent implements OnInit {
  @Input() redes = [];
  @Input() selected = [];
  filteredRedes = [];
  selectedId;
  selectedProgram;
  step = 1;
  redesCount = 0;
  handleInput(event) {
    const query = event.target.value.toLowerCase();
    requestAnimationFrame(() => {
      this.filteredRedes = this.redes.filter(el =>
                                    (el.nome.toLowerCase().indexOf(query) === 0 && query.length >= 2) ||
                                    this.selected.includes(el));
    });
  }
  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  valueChanged(ev, rede) {
    if (ev.detail.checked) {
      this.selected.push(rede);
    } else {
      const id = this.selected.findIndex(el => el.ibgeId === rede.ibgeId);
      this.selected.splice(id, 1);
    }
  }

  redesChanged(ev, rede, network) {
    if (rede.networks === undefined) {
      rede.networks = [];
    }
    if (ev.detail.checked) {
      this.redesCount++;
      rede.networks.push(network);
    } else {
      this.redesCount--;
      const id = rede.networks.findIndex(el => el === network);
      rede.networks.splice(id, 1);
    }
  }

  select(ev, rede) {
    this.selected = rede;
  }

  selectProgram(ev, rede) {
    this.selected = rede;
  }
  next(step?) {
    this.step = step ? step : this.step + 1;
  }
  save() {
    this.popoverController.dismiss(this.selected);
  }
  cancel() {
    this.popoverController.dismiss();
  }

}
