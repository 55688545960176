import { Component, Input } from '@angular/core';
import { TableColumn } from './column.interface';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableComponent {
  tableStyle = 'bootstrap';
  @Input() tableData = [];
  @Input() tableColumns: Array<TableColumn>;
  constructor() {}
}
